import React from 'react';
import APILink from './APILink.js';
import LoginButton from './LoginButton';

const Sidebar = props => {
    let apiLinks = []

    if (props.definitionList === null) {
        props.getOrganizationData()
    } else {
        for (let i = 0; i < props.definitionList.length; i++) {
            if (props.definitionList[i].properties[4].value === "true") {
                apiLinks.push(
                    <APILink 
                        key={i}
                        first={apiLinks.length === 0}
                        apiLinkData={props.definitionList[i]}
                        updateApiLink={props.updateApiLink}
                    />
                )
            }
        }
    }

  return (
    <div className="side-bar">
        <div className="side-bar-header">
        <img src={`${process.env.PUBLIC_URL}/rr-logo.png`} alt="logo"/>
            <h3>Home of the Rent Ready APIs</h3>
        </div>
        <div className="side-bar-body">
            <h3>API DOCS</h3>
            {apiLinks}
        </div>
        <div className="side-bar-signin-button">
            <LoginButton />
        </div>
    </div>
  )
}

export default Sidebar;