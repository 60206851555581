import SwaggerUI from 'swagger-ui-react';
import './App.css';
import React, { Component } from 'react';
import Sidebar from './Sidebar.js'
import VersionDropdown from './VersionDropdown';
import '../node_modules/swagger-ui-react/swagger-ui.css';
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";

const pca = new PublicClientApplication(msalConfig);
const apiRegistryBaseUrl = "https://sandbox-api.rentready.com/swagger-proxy/0.1";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
        definitionList: null,
        apiLink: '',
        apiVersions: [],
        selectedVersion: ''
      }
      this.swaggerhub = this.swaggerhub.bind(this)
      this.getOrganizationData = this.getOrganizationData.bind(this)
      this.updateApiLink = this.updateApiLink.bind(this)
    }

    componentWillMount() {
      this.setState({})
    }

    swaggerhub(inputMethod, inputResource, inputParams) {
      let url = ""
      if (inputParams) {
        url = apiRegistryBaseUrl + inputResource + "?" + inputParams
      } else {
        url = apiRegistryBaseUrl + "/" + inputResource
      }

      return fetch(url, {
          method: inputMethod,
          headers: this.addAuthHeaderIfExists({})
      }).then(response => {
        if (response.ok) {
          return response.json()
        } throw new Error('There was an issue requesting the API')
      }).then(json => {
        return json
      })
    }

    addAuthHeaderIfExists(baseHeaders) {
      const accessToken = sessionStorage.getItem("accessToken");
      return accessToken == null ? baseHeaders : { ...baseHeaders, 'Authorization': accessToken };
    }

    getOrganizationData() {
      let inputParams = "page=0&limit=10&sort=NAME&order=ASC"
    
      this.swaggerhub('GET', '', inputParams).then(response => {
        this.setState({
          definitionList: response.apis
        })
      })
    }

    extractPublishedVersions = (response) => {
      const publishedVersions = [];

      // Loop through the APIs array
      response.apis.forEach(api => {
          // Extract the version and published status
          let version = "";
          let isPublished = false;
          let url = "";
  
          api.properties.forEach(property => {
              if (property.type === "X-Version") {
                  version = property.value;
              } else if (property.type === "X-Published" && property.value === "true") {
                  isPublished = true;
              } else if (property.type === "Swagger") {
                  url = property.url;
              }
          });
  
          // If this version is published, push it to our result array
          if (isPublished) {
              publishedVersions.push(version);
          }
      });
  
      return publishedVersions;
    }

    updateApiVersions(endpoint, apiDefaultVersion) {
      this.swaggerhub('GET', endpoint).then(response => {
        const versions = this.extractPublishedVersions(response) || [];
        this.setState({
          apiVersions: versions,
          selectedVersion: apiDefaultVersion
        })
      });
    }    

    updateApiLink(newLink, apiDefaultVersion) {
      console.log(`link: ${newLink}`);
      // Remove the base URL and split by '/'
      const parts = newLink.replace(apiRegistryBaseUrl + '/', '').split('/');
      
      if (newLink !== '') {
        // Assuming the format is always consistent
        const apiName = parts[0];
        
        this.updateApiVersions(apiName, apiDefaultVersion);
      }

      this.setState({
          apiLink: newLink
      });
  }  
    

    requestInterceptor = (req) => {
      if (req.url.startsWith(apiRegistryBaseUrl)) {
        return {
          ...req,
          headers: this.addAuthHeaderIfExists(req.headers),
        };
      } else {
        return req;
      }
    };

    render() {
      return (
          <MsalProvider instance={pca}>
              <div className="App">
                  <Sidebar 
                      definitionList={this.state.definitionList}
                      updateApiLink={this.updateApiLink}
                      getOrganizationData={this.getOrganizationData}
                  />
                  
                  {this.state.apiLink !== '' && this.state.selectedVersion !== '' && (
                      <div id="api-data">
                          <VersionDropdown 
                              versions={this.state.apiVersions} 
                              selectedVersion={this.state.selectedVersion} 
                              onVersionChange={(version) => {
                                  this.setState({ selectedVersion: version });
                              }}
                          />
                          <SwaggerUI 
                              url={`${this.state.apiLink}/${this.state.selectedVersion}`}
                              requestInterceptor={this.requestInterceptor} 
                              docExpansion="list"
                          />
                      </div>
                  )}
              </div>
          </MsalProvider>
      );
    }
}

export default App;
