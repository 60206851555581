import React from 'react';

const VersionDropdown = ({ versions, selectedVersion, onVersionChange }) => (
    <div className="version-dropdown-container">
        <span className="version-label">Version:</span>
        <select 
            className="version-dropdown"
            value={selectedVersion} 
            onChange={e => onVersionChange(e.target.value)}
        >
            {versions.map(version => (
                <option key={version} value={version}>
                    {version}
                </option>
            ))}
        </select>
    </div>
);

export default VersionDropdown;
