export const msalConfig = {
    auth: {
        clientId: "fdd737c8-2288-4945-9a9d-47269e38facd", // From Azure AD
        authority: "https://login.microsoftonline.com/0b2406ab-bbbf-4b9e-95a2-4f5dd97f73eb", // Replace YOUR_TENANT_ID with your Azure AD tenant ID
        redirectUri: window.location.origin,
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: false,
    }
};

export const loginRequest = {
    scopes: ["openid", "profile", "User.Read"]
};
