import React, { useState } from 'react';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";

function LoginButton() {
    const { instance, accounts } = useMsal();
    const [isSignedIn, setIsSignedIn] = useState(accounts.length > 0); // Determine if there's any signed-in user.

    const handleLogin = () => {
        instance.loginPopup(loginRequest).then(response => {
            sessionStorage.setItem("accessToken", response.accessToken);
            setIsSignedIn(true);  // Set sign-in state to true after successful login.
            window.location.reload();
        }).catch(error => {
            console.error(error);
        });
    };

    const handleLogout = () => {
        sessionStorage.removeItem("accessToken");
        instance.logout(); // This will log the user out.
        setIsSignedIn(false); // Set sign-in state to false after logout.
    };

    return ( 
        <div className="login-button-container">
            <button className="azure-login-button" onClick={isSignedIn ? handleLogout : handleLogin}>
                {isSignedIn ? 'Sign Out' : 'Sign In Private API'}
            </button>
        </div>
    );
}

export default LoginButton;
